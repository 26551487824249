<template>
<div class="bg">
  <!-- header -->
 <div class="title_div" >
    <div>首页</div>
   
</div>
<!-- banner -->
<div class="banner" style="margin-top:2.5vw">
 <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white"  >
    <van-swipe-item  v-for="(item,index) in bannerList" :key="index">
     <div style="background-color:#FFFFFF;">
       <img :src="item.imgUrl" style="border-radius:16px;">
     </div>
    </van-swipe-item>
</van-swipe>
</div>
<!-- 早报 -->
<div class="news">
  <div class="newsInfo" style="background-color:#ffffff;border-radius:10px;margin-top:1.6vh;padding:0.5vh 0;" >
    <div class="newsInfoLeft" style="width:20vw;height:13vw;margin-left:3vw">
      <div style="color:#FF7200;font-weight:900;font-size:5.2vw;padding-left:1vw;margin-top:0.5vh;"><i>早 报</i></div>
      <div style="color:#000000;font-size:3vw;color:#656567;">{{newsList.length?newsList[swiper_index].publishTime:''}}</div>
    </div>
    <div class="newsInfoRight" style="width:68vw;height:13vw;font-size:3vw;color:rgb(157 142 142);display: flex;flex-direction: column;justify-content: center;">
       <van-swipe style="height: 300px;width: 70vw;margin-top:2vw;color:#000;" @change="swiperChange" vertical :autoplay="5000" :show-indicators="false">
          <van-swipe-item  style="overflow: hidden;font-size:2vw;" v-for="(item,index) in newsList" :key="index"><div class="newsLb" @click="goText()" style="font-size:3.2vw;color:#656567;">{{item.title}}</div></van-swipe-item>
      </van-swipe>
    </div>
  </div>
</div>
<!-- 采购入库  销量查询 实时库存 -->
<!-- <div class="buy" style="background-color:red;">
<div class="buyInfo">
<div style="position:relation"> 
  <p style="position:absolute;left:4vw;color:#4E9D82;font-weight:700;font-size:4.5vw;top:80vw" >采购入库明细</p>
  <p style="position:absolute;left:6vw;color:rgb(115 153 140 / 52%);font-size: 0.1vw;top:86vw">Purchase receipt 
</p>
 <p style="position:absolute;left:13vw;color:rgb(115 153 140 / 52%);font-size: 0.1vw;top:90vw"> details </p>
<img src="../assets/image/矩形 10 拷贝 3.png" alt="" style="width: 29.5vw;height: 34vw;">
</div>
<div style="position:relation"> 

   <p style="position: absolute;left: 40vw;top: 80vw;color: #1C74BE;font-size: 4.5vw;font-weight:700; ">销量查询</p>
    <p style="position: absolute; left: 36vw; color: #549cd8;font-size: 0.1vw;top: 86vw;">Sales volume query </p>
  <img src="../assets/image/矩形 10 拷贝.png" alt="" style="width: 29.5vw;height: 34vw;">
  </div>
<div class="yellow" style="position:relation"> 
   <p style="position: absolute;left: 73vw;top: 80vw;color: #BE803A;font-size: 4.5vw;font-weight:700;">实时库存</p>
   <p style="position: absolute;left: 68vw;color: rgb(222 174 120);font-size: 0.1vw;top: 86vw;">Real time inventory</p>
   <img src="../assets/image/a.png" alt=""  style="width: 22vw;height: 22vw;margin-left: 3vw;margin-top: 13vw;"></div>
</div>

</div> -->
<div style="display:flex;padding:1vw;margin-top:1.5vh;">
  <div class="every_btn1" @click="goForm(0)">
    <div style="color:#4E9D82;font-size:4.2vw;margin-top:1.7vh;color: #fff;transform: scale(1);font-weight:700;margin-left:-14vw;">采购入库明细</div>
    <div style="color:#4E9D82;font-size:3.3vw;margin-top:0.5vh;color: #FFD2BE;transform: scale(0.7);margin-left:-21vw;">Purchase receipt</div>
    <div style="color:#4E9D82;font-size:2.8vw;color: #FFD2BE;transform: scale(0.7);margin-left:-32vw;" >details</div>
    <img src="../assets/icon_2/mipmap-xxhdpi/组 16.png" alt="" style="width:22vw;height:21vw;margin-left:24vw;margin-top:-6vw;">
    <img src="../assets/icon_2/mipmap-xxhdpi/矩形 11 拷贝 62.png" alt="" style="width:3vw;height:3vw;margin-left:-34vw;margin-top:-7vw;">
  </div>
  <div class="every_btn2"  @click="goForm(1)">
      <div style="color:#4E9D82;font-size:4.2vw;margin-top:1.7vh;color: #fff;transform: scale(1);font-weight:700;margin-left:-22vw;">销量查询</div>
      <div style="color:#4E9D82;font-size:3.3vw;margin-top:0.5vh;color: #FFD2BE;transform: scale(0.7);margin-left:-24.5vw;">Sales volume </div>
      <div style="color:#4E9D82;font-size:2.8vw;color: #FFD2BE;transform: scale(0.7);margin-left:-32vw;" >query</div>
    <img src="../assets/icon_2/mipmap-xxhdpi/组 17.png" alt="" style="width:22vw;height:21vw;margin-left:25vw;margin-top:-6vw;">
    <img src="../assets/icon_2/mipmap-xxhdpi/矩形 11 拷贝 7.png" alt="" style="width:3vw;height:3vw;margin-left:-34vw;margin-top:-7vw;">
  </div>
 
</div>
<!-- query 有效期查询 结款 -->
<!-- <div>
  <div class="every_btn4">

  </div>
  <div class="every_btn5">

  </div>
</div> -->
<div class="query" style="margin-top:0.5vh;margin-left:-2vw;">
<div class="queryInfo" >
  
  <!-- <div  class="every_btn3" style="position:relative"  @click="goForm(2)">
      <p style="position: absolute; left: 5vw;top: 1vh;color:white;font-size:4.2vw;color:#318CFF;transform: scale(1);font-weight:700;">代销商库存查询</p>
      <p style="position: absolute;left: 1vw;top: 4.5vh;color: white; font-size:3.5vw;color:#318CFF;transform: scale(0.7);">Real time inventory</p>
      <img src="../assets/icon_2/mipmap-xxhdpi/矩形 11 拷贝 7.png" alt="" style="width:3vw;height:3vw;margin-left:-81vw;margin-top:15vw;">
      <img src="../assets/icon_2/mipmap-xxhdpi/矢量智能对象(1).png" alt="" style="width:18vw;height:19vw;margin-left:67vw;margin-top:-18vw;">
  </div> -->
  <div  class="every_btn3" style="position:relative;margin-top:1vh"  @click="goForm(2)">
      <p style="position: absolute; left: 5vw;top: 1vh;color:white;font-size:4.2vw;color:#318CFF;transform: scale(1);font-weight:700;">库存查询</p>
      <p style="position: absolute;left:-1vw;top: 4.5vh;color: white; font-size:3.5vw;color:#318CFF;transform: scale(0.7);">Commercial inventory query </p>
      <img src="../assets/icon_2/mipmap-xxhdpi/矩形 11 拷贝 7.png" alt="" style="width:3vw;height:3vw;margin-left:-81vw;margin-top:15vw;">
      <img src="../assets/icon_2/mipmap-xxhdpi/矢量智能对象(1).png" alt="" style="width:18vw;height:19vw;margin-left:67vw;margin-top:-18vw;">
  </div>
  <div class="every_btn4" style="position:relative;margin-top:1vh"  @click="goForm(3)">
      <p style="position: absolute; left: 5vw;top: 1vh;color:#FF7E40;font-size:4.2vw; font-weight:700;">效期查询</p>
      <p style="position: absolute;left:2vw;top: 4.5vh;color: #FF7E40; font-size:3.5vw;transform: scale(0.7);">Validity query</p>
      <img src="../assets/icon_2/mipmap-xxhdpi/矩形 11 拷贝 62.png" alt="" style="width:3vw;height:3vw;margin-left:-81vw;margin-top:15vw;">
      <img src="../assets/icon_2/mipmap-xxhdpi/矢量智能对象.png" alt="" style="width:18vw;height:19vw;margin-left:67vw;margin-top:-18vw;">
  </div>
 
</div>
</div>

<!-- main -->
  <!-- <div class="main" >
  <div class="mainInfo" >
   
    <el-table
    :header-cell-style="{background: 'linear-gradient(#78B9FC, #569DFE)',padding:'0',color:'#ffffff','text-align':'center',height:'35px'}"
    :data="invoiceList"
    style="border-radius:3vw;color:#7A7A7A;font-size: 10px;"
    :row-class-name="tableRowClassName"
    :row-style="{height:'30px'}"
    :cell-style="{padding:'4px'}"
   
    >
    <el-table-column 
      prop="供货单位"
      label="供货单位"
      width="230"
      align="center"
      >
    
    </el-table-column>
    <el-table-column
      prop="入库日期"
      label="入库日期"
      width="100"
      align="center"
      >
    </el-table-column>
    <el-table-column
      prop="未到票数量"
      label="未到票数量"
      width="100"
      align="center"
      >
    </el-table-column>
    <el-table-column
      prop="未到票金额"
      label="未到票金额"
      width="100"
      align="center"
      >
    </el-table-column>
    <el-table-column
      prop="编码"
      label="编码"
      width="100"
      align="center"
      >
    </el-table-column>
  </el-table>
  </div>
 </div>   -->
  
 
  <!--底部tab栏-->
  <Tabbar2 :choose_index="0" :user_type2="user_type2" :type="pagetype"></Tabbar2>
</div>
</template>

<script>
import Tabbar2 from '@/components/Tabbar2'
export default {
  components :{
    Tabbar2
  },
  data() {
      return { 
         input: '',
         swiper_index:0,//轮播图的索引
        // userType:'',//用户type
        // shopId:'',//账号id
        tableData:[],//列表
        // loading:'',//loading
        user_type2:false,//用户是否区域看板
        pagetype:0,
        bannerList:[],//home轮播图
        newsList:[],//早报list
        menu_list:[],//菜单列表
        invoiceList:[],
        user:"",
        userType:"",
        keys:[]
      };
  },
  created(){
     //获取home轮播图
    this.getHomeBanner();
    //获取早报列表
    this. getNewsList();
   
   
    //获取用户信息
    this.user_api();
    // this.function()
   
  },
  methods:{
//     function () {

// Bus.$emit('val', this.table_data)

// },
    user_api(){
      this.$axios.post(this.$api.user_api).then(res=>{
        console.log(res,"用户信息")
        localStorage.setItem("user_info", JSON.stringify(res.data.data));
        this.user=res.data.data.userId;
        this.userType = res.data.data.type;
        console.log(this.user,"this.userId")
        //  this.invoiceData(this.user);
         this.menu_api(this.userType);
         this.functionList(this.userType);
      })
    },
    goForm(e){
      console.log(e)
      console.log(this.menu_list[e])
      let id = this.menu_list[e].id;
     
      this.$router.push('./info2?type=' + id);
      // this.functionInfo(id)
    },
    menu_api(userType){
      // localStorage.setItem("userType",this.userType);
      this.$axios.post(this.$api.menu_api,{
        userType:this.userType
      }).then(res=>{
        console.log(res.data.data,"用户当前菜单1111111111111111")
        // this.loading.close();
        this.table_data = res.data.data;
        // localStorage.setItem("choose_item", JSON.stringify(this.table_data));
        //若是总店账号 显示博爱供应商应付报表
        if(this.shopId=='%%'){
          this.table_data.push({
            name:'博爱供应商应付报表',
            type:1,
          })
          this.table_data.push({
            name:'以销定结供应商应付报表',
            type:2,
          })
        }
        console.log(res,"67676")
      })
    },
    //选择队列
    // goInfo(item){
    //   console.log(item,"item")
    //   if(item.type==1||item.type==2){
    //     //type为1 进入博爱供应商应付报表
    //     this.$router.push('./supplier?pagetype='+item.type);
    //   }else{
    //     localStorage.setItem("choose_item", JSON.stringify(item));
    //     this.$router.push('./info');
    //   }
    // },
    swiperChange(e){
      this.swiper_index = e;
      // console.log(e,"swiper");
    },
    //斑马表格
      tableRowClassName({ rowIndex }) {
      if ((rowIndex + 1) % 2 === 0) {
        return "oddRow";
      }
      return "evenRow";
    },
  //去文章列表
  goText(){
    this.$router.push("./text");
  },
  //获取home轮播图
  getHomeBanner(){
    this.$axios.get(this.$api.getHomeBanner,{
     
          }).then(res=>{
           this.bannerList=res.data.page.list
              console.log(this.bannerList,"this.bannerList")
         
        })
    },
    //获取早报列表
    getNewsList(){
      this.$axios.get(this.$api.getNewsList,{
          }).then(res=>{
            // console.log(res,"zaobao")
          this.newsList=res.data.page.list
          console.log(this.newsList,"zaobao")
        })
    },
    //查询首页功能列表
    functionList(type){
      this.$axios.post(this.$api.functionList,{
        userType:type
          }).then(res=>{
          this.menu_list = res.data.list;
          console.log(this.menu_list,"z111obao")
        })
      
    },
    // //查询供应商入库未到发票数据
    
    // invoiceData(user){
    //   // console.log(this.user,"aaaaa")
    //   this.$axios.post(this.$api.invoiceData,{
    //       userId:this.user,
    //       }).then(res=>{
    //       console.log(res,"2")
    //       this.invoiceList=res.data.data
    //       console.log(this.user,"3")
    //       console.log(this.invoiceList,"this.invoiceList")
          
    //       for(let item of this.invoiceList){
    //         // console.log(item,"aaaaaaaaaaaaaaaa")
    //        this.keys= Object.getOwnPropertyNames(item)
    //        console.log(this.keys,"asssssssssssss")
    //         for(var i=0;i<this.keys.length;i++){
    //           this.keys[i]
    //         }
    //       }
    //     })
      
    // },
  }
}
</script>
<style>

.oddRow {
 background: #ffffff!important;
}

  .evenRow {
background: #E1F2F9!important;
}

.el-table td {
 padding:0 ;
 border: none;


 
}
.el-table thead {
 height: 4vh;
}

.el-input__inner{
  border-radius: 22px;
  width: 96%;
  height: 32px;
  margin-left: 2vw;
  margin-top:1vw;
}
.el-icon-search:before{
  margin-left: 2.5vw;
}
</style>
<style scoped>
*{
 margin: 0;
 padding: 0;
}
.bg{
  background-color: #FAF7FA;
  min-height:100vh;
}
.title_div{
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 height:8vh;
 background-image: linear-gradient(#3F98EB, #006ED5 );
 color:#FFFFFF;
 font-size:4.6vw;
 

}
.top{
 display: flex;
 justify-content: space-around;
 align-items: center;
 margin-top: 7vw;

}
.search_input{
 outline: none;
 border:none;
 border-radius: 7vw;
 width:88vw;
 height:7vw;
 margin-left:5vw;
 margin-top: 1.5vw;

 
}
.banner{
 
 width: 100%;
 height: 30vw;
 /* background: green; */
 display: flex;
 align-items: center;
 justify-content: center;
 margin-top: 2vw;
 
 }
.banner img{
 width: 94vw;
 height: 30vw;
}
.news{
 width: 100%;
 height: 14vw;
 /* background: green; */
 display: flex;
 align-items: center;
 justify-content: center;
 margin-top: 1vw;
 
 }
 .newsInfo{
  width: 94vw;
  height: 13vw;
  /* background: red; */
  display: flex;
  /* flex-direction: column; */
  /* justify-content: center; */
  
}
.buy{
  width: 100%;
 height: 33vw;
 /* background: green; */
 display: flex;
 align-items: center;
 justify-content: center;
 margin-top: 1vw;
}
.buyInfo{
 width: 94vw;
  height: 34vw;
  /* background: red; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.buyInfo div{
  width: 29.5vw;
  height: 34vw;
  /* background: purple; */
}


.every_btn1{
  width:46vw;
  margin-left: 2vw;
  height:18vh;
  border-radius:16px;
  background-image: url('../assets/icon_2/mipmap-xxhdpi/矩形 4 拷贝 8.png');
  background-size:100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.every_btn2{
  width:46vw;
  margin-left: 2vw;
  height:18vh;
  border-radius:16px;
  background-image: url('../assets/icon_2/mipmap-xxhdpi/矩形 4 拷贝 9.png');
  background-size:100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.every_btn3{
  width:30vw;
  margin-left: 2vw;
  height:18vh;
  border-radius:16px;
  background-image: url('../assets/icon_2/mipmap-xxhdpi/矩形 4 拷贝 11.png');
  background-size:100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.every_btn4{
  width:30vw;
  margin-left: 2vw;
  height:18vh;
  border-radius:16px;
  background-image: url('../assets/icon_2/mipmap-xxhdpi/矩形 4 拷贝 13.png');
  background-size:100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.query{
   width: 100%;
 height: 23.5vw;
 /* background: green; */
 display: flex;
 align-items: center;
 justify-content: center;
 margin-top: 2vw;
}
.queryInfo{
  width: 94vw;
  height: 23.5vw;
  /* background: red; */
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
}
.queryInfo div{
  height:19vw;
  width: 94vw;
  /* background: purple; */


}
.main{
  width: 100%;
 height: auto;
 /* background: green; */
 display: flex;
 align-items: center;
 justify-content: center;
 margin-top: 2vw;
}
.mainInfo{
   width: 94vw;
  height: auto;
  height: 40vh;
  overflow: scroll;
  /* background:  purple; */
  /* border-radius: 3vw; */
  /* border: none; */
  
  
}
.yellow{
  background: url("../assets/icon_2/mipmap-xxhdpi/矩形 4 拷贝 11.png") no-repeat;
  z-index: 1;
}
.pay{
  background: url("../assets/icon_2/mipmap-xxhdpi/矩形 4 拷贝 11.png") no-repeat;
  background-size: 100% 100%;
    z-index: 1;
   
}
.van-swipe{
  width: 94vw;
  height: 30vw;
}
 .my-swipe .van-swipe-item {
  width: 400px;
    color: #fff;
    font-size: 20px;
    line-height: 150px;
    text-align: center;
    background-color: #39a9ed;
  }
  .my-swipe .van-swipe-item img{
    display: block;
      width: 94vw;
      height: 30vw;
  }
.el-table--scrollable-x .el-table__body-wrapper {
    overflow: auto;
}

.newsLb{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}


</style>