<template>
    <!--tab栏-->
    <div>
        <!--填充高度-->
        <div style="height:11vh;"></div>
        <!--主体-->
        <div class="tabbar_body">
            <div v-for="(item,index) in tabbar_list" :key="index" class="every_tab" @click="changeTab(item.url)"> 
                <img :src="choose_index==index?item.icon_xuan:item.icon_wei" style="width:5vw;height:5vw;"> 
                <div  class="tabbar_font" :style="choose_index==index?'color:#04A9F3;':''">{{item.name}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    choose_index:{
        type:Number,
        default: 1,
    },
  },
  data() {
    return {
        tabbar_list:[
            {name:'首页',name2:'首页',url:'index2',icon_wei:require('@/assets/tabbar/jingyingfenxi_wei.png'),icon_xuan:require('@/assets/tabbar/jingyingfenxi_xuan.png')},
            {name:'业务流程',name2:'业务流程',url:'home2',icon_wei:require('@/assets/tabbar/zongbukanban_wei.png'),icon_xuan:require('@/assets/tabbar/zongbukanban_xuan.png')},
            {name:'我的',name2:'我的',url:'my2',icon_wei:require('@/assets/tabbar/wode_wei.png'),icon_xuan:require('@/assets/tabbar/wode_xuan.png')},
        ]
    };
  },
  methods:{
      changeTab(url){
          this.$router.push('./' + url);
      }
  }
}
</script>
<style scoped>
.tabbar_body{
    height: 9vh;
    width: 100vw;
    background-color:#FFFFFF;
    position:fixed;
    bottom: 0;
    left:0;
    z-index: 20;
    display: flex;
    justify-content: space-around;
}
.every_tab{
    width:20vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.tabbar_font{
    font-size:3.2vw;
    margin-top:0.8vh;
}
</style>